body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  font-family: "Open Sans", sans-serif;
  overflow-x: hidden;
}

.header {
  display: flex;
  align-items: center;
  justify-content: end;
  padding: 10px 20px;
  margin-bottom: 10px;
}

.navbar {
  background-image: url("../../images/bg1.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  width: 60%;
  z-index: 1000;
  transform: translateX(100%);
  transition: transform 0.5s ease-in-out;
}

.navbar-open {
  transform: translateX(100);
}

.menu-icon {
  display: none;
  cursor: pointer;
  z-index: 1001;
}

.navbarList {
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: center;
}

.navbarList li {
  margin: 20px 0;
  white-space: nowrap;
}

.navbarAnchor {
  padding: 10px 10px;
  display: block;
  color: #fff;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 20px;
  font-weight: 500;
  transition: color 0.2s ease-in;
}

.navbarAnchor:hover {
  color: #fff;
}

.bookpass-button {
  background-color: #730000;
  color: #fff;
  border: none;
  padding: 15px 15px;
  cursor: pointer;
  font-size: 22px;
  border-radius: 4px;
  margin-left: 10px;
}

@media (min-width: 768px) {
  .navbar {
    position: relative;
    transform: none;
    width: auto;
    display: flex;
    align-items: center;
    background: none;
  }

  .navbarList {
    display: flex;
    flex-direction: row;
    margin-left: auto;
  }

  .navbarList li {
    margin: 0 0px;
  }

  .menu-icon {
    display: none;
  }

  .navbarAnchor {
    font-size: 18px;
  }
}

.navbarAnchor:hover {
  color: #730000;
  border-bottom: 2px solid #730000;
}

@media (max-width: 767px) {
  .menu-icon {
    display: block;
    font-size: 24px;
    color: #fff;
  }

  .navbarAnchor {
    font-size: 20px;
  }

  .navbarList li {
    text-align: left;
    margin-left: 17px;
  }
}
