
.about1 h1 {
    font-size: 36px;
    margin-bottom: 20px;
    padding-left: 100px;
}
.about1 p {
    font-size: 18px;
    line-height: 1.8;
}

.about {
    text-align: start;
    padding-top: 30px;
    padding-left: 100px;
    padding-right: 100px;
    padding-bottom: 30px;
    text-align: justify;
    text-justify: inter-word;
}
.about-image {
    /* margin-top: 25px;
    margin-bottom: 25px; */
    /* padding: 20px;   */
    padding-left: 40px;
    
}
.about h1 {
    font-size: 36px;
    margin-bottom: 20px;
}
.about p {
    font-size: 18px;
    line-height: 1.8;
    padding-left: 40px;
    padding-right: 50px;
}

.team {
    display: flex;
    justify-content: space-around;
    margin-top: 40px;
    flex-wrap: wrap;
    
}
.team-member {
    text-align: center;
    margin-bottom: 20px;
}
.team-member img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
}
.team-member h3 {
    font-size: 20px;
    margin: 10px 0 5px;
}
.team-member p {
    font-size: 16px;
    color: #555;
}

/* Mobile styles */
@media (max-width: 768px) {
    .about1 h1 {
      font-size: 28px; /* Adjusted font size for tablet */
      padding-left: 20px; /* Reduced padding */
    }
  
    .about1 p {
      font-size: 16px; /* Adjusted font size for tablet */
    }
  
    .about {
      padding-left: 20px; /* Reduced padding */
      padding-right: 20px; /* Reduced padding */
    }
  
    .about h1 {
      font-size: 28px; /* Adjusted font size for tablet */
    }
  
    .about p {
      font-size: 16px; /* Adjusted font size for tablet */
      padding-left: 20px; /* Reduced padding */
      padding-right: 20px; /* Reduced padding */
    }
  
    .team {
      flex-direction: column; /* Stack team members vertically */
      align-items: center; /* Center align items */
    }
  
    .team-member img {
      width: 120px; /* Smaller image size for tablet */
      height: 120px; /* Smaller image size for tablet */
    }
  
    .team-member h3 {
      font-size: 18px; /* Smaller font size for tablet */
    }
  
    .team-member p {
      font-size: 14px; /* Smaller font size for tablet */
    }
  }
  
  /* Mobile View */
  @media (max-width: 480px) {
    .about1 h1 {
      font-size: 24px; /* Smaller font size for mobile */
      padding-left: 10px; /* Further reduced padding */
    }
    .about-image{
        padding-left: 10px;
    }
    .about1 p {
      font-size: 14px; /* Smaller font size for mobile */
    }
  
    .about {
      padding-left: 10px; /* Further reduced padding */
      padding-right: 10px; /* Further reduced padding */
    }
  
    .about h1 {
      font-size: 24px; /* Smaller font size for mobile */
    }
  
    .about p {
      font-size: 14px; /* Smaller font size for mobile */
      padding-left: 10px; /* Further reduced padding */
      padding-right: 10px; /* Further reduced padding */
    }
  
    .team {
      flex-direction: column; /* Stack team members vertically */
      align-items: center; /* Center align items */
    }
  
    .team-member img {
      width: 100px; /* Smaller image size for mobile */
      height: 100px; /* Smaller image size for mobile */
    }
  
    .team-member h3 {
      font-size: 16px; /* Smaller font size for mobile */
    }
  
    .team-member p {
      font-size: 12px; /* Smaller font size for mobile */
    }
  }