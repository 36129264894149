
.gallery {
  position: relative;
  width: 100%;
  cursor: pointer;
}

.gallery-item {
  padding: 10px;
}

.gallery-image {
  width: 100%;
  height: 100%;
  border-radius: 8px;
}

.loader {
  text-align: center;
  padding: 20px;
  font-size: 16px;
}

.image-wrapper {
  width: 100%;
  height: 400px; /* Adjust based on your design */
  overflow: hidden; /* Hide overflow if the image is larger than the container */
}

.image-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Cover the container while maintaining aspect ratio */
}
