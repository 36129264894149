@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Global scrollbar styles */
::-webkit-scrollbar {
  width: 12px; /* Width of the scrollbar */
  height: 12px; /* Height of the scrollbar (for horizontal scrollbars) */
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; /* Background of the track */
  border-radius: 10px; /* Optional: to give it rounded corners */
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: #888; /* Color of the scrollbar handle */
  border-radius: 10px; /* Optional: to give it rounded corners */
  border: 3px solid #f1f1f1; /* Space between the handle and the track */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Color when hovering over the scrollbar */
}

/* Firefox scrollbar styling */
* {
  scrollbar-width: thin; /* Thinner scrollbar */
  scrollbar-color: #ee8525 #e04a28; /* Handle color and track color */
}

.app-content {
  padding: 20px;
  transition: transform 0.5s ease-in-out;
  transform: translateX(0);
}

.app-content.shifted {
  transform: translateX(-60%);
}

/* Ensure the body takes up the full height of the viewport */
body,
html {
  height: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
}

/* The entire app container */
.background.app-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Ensure the minimum height of the app covers the whole viewport */
  transition: transform 0.3s ease-in-out; /* For smooth shifting */
}

/* Main content area */
main {
  flex: 1;
}

/* Footer should stick to the bottom if there's little content */
footer {
  padding: 20px;
  text-align: center;
  width: 100%;
}

.main-logo {
  width: 350px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.main-logo-div {
  width: 100%;
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 768px) {
  .main-logo {
    width: 250px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
