.banner-content {
  display: flex;
  flex-direction: column; /* Stack text and button vertically */
  align-items: center;
  text-align: center;
  /* justify-content: center; */
  padding: 20px; /* Add padding for spacing */
  /* height: 100vh; */
}

.banner-image {
  width: 300px; /* Adjust width as needed */
  height: auto; /* Maintain aspect ratio */
  margin-bottom: 0px; /* Space between the logo and text */
  /* display: none; */
  /* justify-content: center; */
  /* align-items: center; */
}

.banner-text h1 {
  font-size: 3rem; /* Adjust font size as needed */
  color: #333; /* Text color */
  margin-bottom: 20px; /* Space between text and button */
}

.book-passes {
  color: white;
  background-color: #730000; /* Button background color */
  padding: 15px 30px; /* Increase padding for a larger button */
  border: none;
  cursor: pointer;
  font-size: 1.5rem !important; /* Increase font size */
  transition: background-color 0.3s ease;
  border-radius: 5px; /* Optional: Add some rounding to the corners */
  width: 200px; /* Optional: Set a specific width */
  height: 60px; /* Optional: Set a specific height */
  white-space: nowrap;
}
.coming-soon {
  font-size: 30px;
  font-family: "Futura";
  color: #a00000;
}

.countdown-timer{
  display: flex;
  justify-content: center;
}
.book-passes:hover {
  background-color: #a00000; /* Darker shade on hover */
}

/* Responsive styles for tablets */
@media (max-width: 768px) {
  .banner-image {
    width: 120px; /* Adjust width for tablets */
  }

  .banner-text h1 {
    font-size: 2.5rem; /* Slightly smaller font for tablet */
  }

  .book-passes {
    font-size: 1.3rem !important;
    width: 180px;
    height: 55px;
    padding: 12px 20pxbackground;
  }
}

/* Mobile View */
@media (max-width: 480px) {
  .banner-content {
    padding: 20px; /* Add some padding for better spacing */
    height: auto; /* Adjust height for mobile */
    padding-top: 100px;
  }

  .banner-image {
    width: 200px; /* Adjust width as needed */
    height: auto; /* Maintain aspect ratio */
    margin-bottom: 20px; /* Space between the logo and text */
    /* display: none; */
    /* justify-content: center; */
    /* align-items: center; */
  }
  .banner-text h1 {
    font-size: 2rem; /* Smaller font for mobile */
    margin-bottom: 15px; /* Space between text and button */
  }
}