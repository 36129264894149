/* General Styles */
.contact1 {
  min-height: 15vh;
  margin-top: 25px;
  margin-bottom: 25px;
}

.contact1 h1 {
  font-size: 36px;
  padding: 35px 100px;
  margin-left: 10px;
}

.contact-container {
  padding-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Arial, sans-serif;
  background: transparent;
}

.contact-form {
  width: 100%;
  max-width: 600px;
  padding: 20px;
  border-radius: 10px;
  background: transparent;
}

.contact-form label {
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
}

.contact-form .row {
  display: flex;
  gap: 1rem;
}

.contact-form label {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  flex: 1;
}
.connect-checkboxes input {
  width: auto !important;
}
.contact-form input,
.contact-form textarea {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.checkbox-group {
  margin-bottom: 20px;
  border-radius: 10px;
}

.checkbox-group legend {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.connect-checkboxes {
  display: flex;
  gap: 20px;
}

.connect-checkboxes label {
  display: flex;
  align-items: center;
  font-size: 1rem;
  color: #333;
  flex-direction: row;
}

.connect-checkboxes input[type="checkbox"] {
  margin-right: 10px;
  margin-top: 10px;
}

.contact-form button {
  padding: 10px 20px;
  background-color: #800000;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
  transition: background-color 0.3s ease;
}

.contact-form button:hover {
  background-color: #4a0000;
}

/* Tablet View */
@media (max-width: 768px) {
  .contact1 h1 {
    font-size: 28px; /* Adjusted font size for tablet */
    padding: 20px 50px;
    margin-left: 5px;
  }

  .contact-form {
    max-width: 500px; /* Slightly smaller form width for tablet */
    padding: 15px; /* Adjusted padding */
  }

  .contact-form .row {
    flex-direction: column; /* Stack input fields vertically */
  }

  .connect-checkboxes {
    flex-direction: column; /* Stack checkboxes vertically */
    gap: 10px; /* Reduce gap for tablet */
    align-items: flex-start;
  }
}

/* Mobile View */
@media (max-width: 480px) {
  .contact1 h1 {
    font-size: 24px; /* Smaller font size for mobile */
    padding: 15px 30px;
    margin-left: 0;
    text-align: center; /* Center the heading on mobile */
  }

  .contact-container {
    padding-top: 10px;
    flex-direction: column;
    align-items: stretch; /* Full-width on mobile */
  }

  .contact-form {
    max-width: 100%;
    padding: 10px;
  }

  .connect-checkboxes {
    flex-direction: column;
    gap: 5px; /* Further reduced gap for mobile */
    align-items: flex-start;
  }

  .contact-form button {
    width: 100%; /* Full-width button on mobile */
    padding: 15px; /* Increase padding for better touch interaction */
  }
}
