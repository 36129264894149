.header-text {
  text-align: center;
  font-size: 30px;
  color: #730000;
  margin-top: 20px;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}
.bookpass-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px;
  gap: 20px;
  flex-wrap: wrap;
}

.passes {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.quantity-selector {
  display: flex;
  align-items: flex-end;
  gap: 10px;
  margin-left: 20px;
}

.quantity-selector button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.quantity-selector button:hover {
  background-color: #0056b3;
}

.quantity-selector button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.quantity-selector button.disabled {
  background-color: #ccc;
  color: #666;
}

.pass-content h3,
.pass-content p {
  margin: 0;
}

.pass-sold-out-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #8b2f3467; /* Semi-transparent red */
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: bold;
  text-transform: uppercase;
  z-index: 10;
}

.bookpass-main {
  position: sticky;
  top: 20px;
  flex: 0 0 300px;
  padding: 30px;
  border-radius: 8px;
  font-family: Arial, sans-serif;
}
.bookpass-user-details {
  margin-top: 20px;
  flex: 0 0 300px;
  background-color: #f5f1e3;
  padding: 30px;
  border-radius: 8px;
  font-family: Arial, sans-serif;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.bookpass-details {
  top: 20px;
  flex: 0 0 300px;
  background-color: #f5f1e3;
  padding: 30px;
  border-radius: 8px;
  font-family: Arial, sans-serif;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.bookpass-details p {
  margin: 10px 0;
  font-family: "Courier New", Courier, monospace;
  font-weight: bold;
}

.bookpass-details input[type="text"] {
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  box-sizing: border-box;
}

.bookpass-details button:hover {
  background-color: #8c3e21;
}

.bookpass-details p:first-child {
  font-weight: bold;
}

.bookpass-details p:nth-child(2) {
  font-weight: bold;
}

.bookpass-details p:nth-child(4),
.bookpass-details p:nth-child(5) {
  color: #27ae60;
  font-weight: bold;
}

.bookpass-details p:nth-child(5) {
  font-size: 18px;
  color: #d35400;
  font-weight: bold;
}

.bookpass-details input {
  border: none;
  background: none;
  font-size: 16px;
  font-family: "Courier New", Courier, monospace;
}
.bookpass-details .apply-coupon-button {
  background-color: #f5f5f5;
  color: #333;
  padding: 10px;
  border: 2px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  display: inline-block;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  margin-bottom: 10px;
}

.bookpass-details .apply-coupon-button:hover {
  background-color: #e0e0e0;
  border-color: #999;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}
.checkout-button {
  background-color: #730000;
  color: #f4f4f4;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.checkout-button:hover {
  background-color: #8c3e21;
}
.bookpass-user-details {
  font-family: "Courier New", Courier, monospace;
}

@media only screen and (max-width: 768px) {
  .bookpass-user-details {
    width: 75% !important;
    margin-left: 15px;
    padding: 20px !important;
  }
  .modal-content-TAC {
    width: 90% !important;
  }
  .bookpasses1 h1 {
    font-size: 28px;
    padding: 20px 40px;
  }

  .bookpass-container {
    flex-direction: column;
    padding: 15px 15px 15px 0px;
  }

  .bookpass-details {
    padding: 20px;
    margin: 0 auto;
    width: 100%;
  }

  .bookpass-details p,
  .bookpass-details input[type="text"],
  .quantity-selector span {
    font-size: 14px;
  }

  .checkout-button,
  .apply-coupon-button {
    padding: 8px 16px;
    font-size: 14px;
  }
}

@media only screen and (max-width: 480px) {
  .bookpass-details input[type="checkbox"] {
    width: 20px;
  }
  .app-content {
    padding: 5px;
  }
  .product-name {
    word-wrap: normal !important;
    white-space: none !important;
    font-size: 15px !important;
    padding: 15px !important;
    width: 160px !important;
    min-width: 160px !important;
  }
  .product-price {
    white-space: nowrap !important;
  }

  .product-details {
    gap: 5px !important;
  }
  .bookpass-container {
    padding: 0 !important;
  }
  .card {
    padding: 10px !important;
  }
  .quantity-control {
    margin-left: 2px;
  }
  .bookpasses1 h1 {
    font-size: 24px;
    padding-left: 20px;
    padding-top: 30px;
    margin-left: 2px;
  }

  .bookpass-details {
    padding: 15px;
    text-align: left;
  }

  .bookpass-details p,
  .bookpass-details input[type="text"],
  .quantity-selector span {
    font-size: 12px;
  }

  .checkout-button,
  .apply-coupon-button {
    padding: 6px 10px;
    font-size: 18px;
    margin-top: 5px;
  }
}

.pass-item {
  width: 100%;
}

.card {
  position: relative; /* Enables the overlay effect */
  transition: transform 0.3s ease;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 10px;
  padding: 15px;
  gap: 15px; /* Space between the product name and details */
}

.product-name {
  background-color: #8b2f34; /* Background color for the product name */
  color: #fff; /* Text color */
  font-weight: bold;
  text-align: center;
  padding: 15px;
  border-radius: 8px;
  font-size: 18px;
  width: 200px;
  min-width: 200px;
  white-space: nowrap;
  font-family: "Courier New", Courier, monospace;
}

.product-details {
  display: flex;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
}

.product-price {
  font-size: 18px;
  color: #8b2f34;
}

.quantity-control {
  display: flex;
  align-items: center;
  gap: 10px;
}

.quantity-control .btn {
  background-color: #8b2f34;
  color: #fff;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  font-size: 18px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.quantity-control .btn.disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.quantity {
  font-size: 18px;
  color: #333;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Overlay background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content-TAC {
  /* background: #fff; */
  background-image: url("../../images/bg1.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  /* background-position: center;  */
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 60%;
  text-align: center; /* Center align content */
  display: flex;
  flex-direction: column;
  align-items: center; /* Center items horizontally */
  overflow-y: scroll;
  height: 90vh;
  text-align: justify;
  text-justify: inter-word;
}

.modal-content-TAC a {
  color: #730000;
  font-size: 16px;
}
.modal-content-TAC h1 {
  font-size: 30px;
  color: black;
  margin-top: 10px;
}
.modal-content-TAC h2 {
  font-size: 25px;
  color: black;
  margin-top: 10px;
}
.modal-content-TAC h3 {
  font-size: 20px;
  color: black;
  margin-top: 10px;
}
.modal-content-TAC p {
  font-size: 15px;
  color: black;
}
.modal-content-TAC button {
  background-color: #730000;
  color: #fff;
  border: none;
  padding: 5px 15px;
  cursor: pointer;
  font-size: 22px;
  border-radius: 4px;
}

.modal-logo {
  width: 200px; /* Adjust width as needed */
  height: 150px; /* Maintain aspect ratio */
}

.modal-content form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%; /* Ensure form takes full width */
}

.modal-content label {
  display: flex;
}

.modal-content input {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.modal-content button {
  background-color: #730000;
  color: #fff;
  border: none;
  padding: 5px 15px;
  cursor: pointer;
  font-size: 22px;
  border-radius: 4px;
}

.modal-close-div {
  width: 100%;
  text-align: end;
}

.margin-class {
  margin-top: 10% !important;
}

.buttn-bg {
  background-color: #730000;
}
@media only screen and (max-width: 480px) {
  .margin-class {
    margin-top: 30% !important;
  }
}