/* Footer.css */
.footer-container {
  color: black;
  padding: 30px 108px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap; /* Ensures wrapping on smaller screens */
}

.footer-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 20px; /* Gap between footer sections */
}

.footer-contact{
  display: flex;
  align-items: center;
  gap: 0px; /* Gap between icons and text */
}
.footer-address {
  display: flex;
  align-items: center;
  gap: 20px;

}

.footer-icons {
  display: flex;
  gap: 15px; /* Gap between social media icons */
}

.contact-icon, .location-icon {
  font-size: 24px;
}

.contact-item {
  color: black;
  text-decoration: none;
  font-size:28px;
  display: flex; /* Ensure phone icon and number are in a row */
  align-items: center;
}

.icon-link a {
  color: black;
  font-size: 24px;
  transition: color 0.3s;
}

.icon-link a:hover {
  color: #fff; /* Example hover color */
}

.footer-address a {
  color: black;
  text-decoration: none;
  font-size: 30px;
  display: flex;
  align-items: center;
  gap: 8px; /* Gap between address icon and text */
}

/* Responsive Design for Tablets and Mobile Screens */
@media (max-width: 768px) {
  .footer-row {
    flex-direction: column; /* Stacks elements on smaller screens */
    align-items: flex-start;
  }

  .footer-contact,
  .footer-address {
    justify-content: flex-start;
    gap: 15px;
  }

  .footer-container {
    padding: 20px;
  }

  .footer-icons {
    margin-top: 5px;
    font-size: larger;
  }
}

/* Additional Media Query for Smaller Mobile Screens */
@media (max-width: 480px) {
  .contact-item, .footer-address a {
    font-size: 16px; /* Smaller font size for mobile screens */
  }

  .contact-icon, .location-icon {
    font-size: 20px; /* Smaller icon size for mobile screens */
  }

  .footer-container {
    padding: 15px;
  }
}
