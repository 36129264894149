.container {
  max-width: 800px;
  margin: 0 auto;
  font-size: 18px;
  /* background: #fff; */
  padding: 20px;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
  border-radius: 8px;
  text-align: justify;
  text-justify: inter-word;
}
.container h1 {
  font-size: 35px;
  text-align: center;
  color: black;
  font-weight: bold;
  margin-bottom: 10px;
}
.container h2 {
  font-size: 25px;
  color: black;
  margin-top: 10px;
  font-weight: 900;
  border-bottom: 1px solid black;
  width: fit-content;
  margin-bottom: 5px;
}

ul {
  margin-left: 20px;
}
ul li {
  margin-bottom: 10px;
}

.logo-image {
  width: 200px;
}

.logo-image-main {
  width: 100%;
  display: flex;
  justify-content: center;
}

.highlight {
  font-weight: bold;
  color: #730000;
}

.deadline {
  color: black;
  font-weight: bold;
}

.apply-div {
  margin-top: 20px;
  width: 100%;
  text-align: center;
}
.apply-button {
  background-color: #730000;
  color: #fff;
  border: none;
  padding: 15px 25px;
  cursor: pointer;
  font-size: 22px;
  border-radius: 4px;
}
