.background {
  background-image: url("../images/bg1.jpg");
  background-size: cover; /* Ensures the image covers the entire div */
  background-repeat: no-repeat; /* Prevents the image from repeating */
  background-position: center; /* Centers the image */
  min-height: 100vh; /* Full viewport height */
  background-attachment: fixed; /* Optional: Keeps the background image fixed during scrolling */
}

@media (max-width: 768px) {
  .background {
    background-size: cover; /* Ensures the image covers the container */
    background-position: center; /* Ensures the image remains centered */
  }
  .main-banner-main {
    min-height: 60vh; /* Full viewport height */
  }
}
